<!--积分兑换卡券-->
<template>
  <div class="mall-goods-shop">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入卡券名称搜索" @input="kwChange" clearable class="mr15" />
        <el-select v-model="tableParams.goodsStatus" placeholder="选择状态" @change="reload" clearable>
          <el-option v-for="item in ObjToOpt(exchangeGoodsStatusObj)" :key="item.value" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">添加兑换卡券</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }">
      <el-table-column label="序号" width="50" prop="sortNum">
      </el-table-column>
      <el-table-column label="兑换卡券" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cardName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="兑换所需积分" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.requiredPoints || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="兑换所需现金" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.requiredAmount || "0" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsStatus == 0 ? '下架' : '售卖中' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" v-if="scope.row.goodsStatus == 0"
            @click.stop="onDel(scope.row)">删除</el-button>

          <el-button type="text" size="medium" @click.stop="onUp(scope.row)"
            v-if="!scope.row.goodsStatus">上架</el-button>
          <el-button type="text" size="medium" @click.stop="onDown(scope.row)" v-else>下架</el-button>
          <el-button type="text" size="medium" @click.stop="sort(scope.row)">排序</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" @close="closeDialog"
      append-to-body width="30%">
      <!-- 表单提交 -->
      <el-form :model="cardInfo" :rules="rules" ref="form-ref" label-width="120px">
        <section class="form-main">
          <el-form-item label="选择卡券" prop="cardId">
            <el-input v-if="cardInfo.cardConfigId" v-model="cardInfo.cardName" :disabled="true">
            </el-input>
            <el-select v-else v-model="cardInfo.cardId" filterable remote reserve-keyword
              :disabled="this.editTitle == '编辑兑换卡券'" placeholder="搜索选择平台卡券" :remote-method="remoteMethod"
              :loading="loading" :popper-class="'custom-select-dropdown'">
              <div style="min-height: 180px">
                <el-option v-for="item in cardOption" :key="item.cardId" :label="item.cardName" :value="item.cardId">
                </el-option>
              </div>
              <div class="custom-pagination">
                <el-pagination background layout="prev, pager, next" :page-size="cardParams.size" :total="total"
                  @current-change="handlePageChange" :current-page.sync="currentPage">
                </el-pagination>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="兑换所需积分" prop="requiredPoints">
            <el-input type="number" min="0" v-model="cardInfo.requiredPoints"
              oninput="if(value.length>10)value=value.slice(0,10)" placeholder="请输入积分数" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
          </el-form-item>
          <el-form-item label="兑换所需现金" prop="requiredAmount">
            <el-input type="number" min="0" v-model="cardInfo.requiredAmount"
              oninput="if(value.length>10)value=value.slice(0,10)" placeholder="请填写兑换所需现金" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          ">
              <template slot="prepend">￥</template></el-input>
          </el-form-item>
          <el-form-item label="每人限兑数" prop="quotaNum">
            <el-input type="number" v-model="cardInfo.quotaNum" oninput="if(value.length>10)value=value.slice(0,10)"
              min="0" placeholder="不填表示无限制" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          ">
              <template slot="append">件</template></el-input>
          </el-form-item>
          <el-form-item label="排序值" prop="sortNum">
            <el-input type="number" v-model="cardInfo.sortNum" oninput="if(value.length>10)value=value.slice(0,10)"
              min="0" placeholder="输入排序值" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="cardInfo.goodsStatus">
              <el-radio :label="0">下架</el-radio>
              <el-radio :label="1">售卖</el-radio>
            </el-radio-group>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">确定</el-button>
        </section>
      </el-form>
    </el-dialog>
    <!--排序-->
    <el-dialog title="排序" :visible.sync="sortShow" :close-on-click-modal="false" append-to-body width="30%">
      <section class="form-main">
        <el-form :model="cardInfo" :rules="rules" ref="form-replenish" label-width="100px">
          <el-form-item label="排序值" prop="sortNum">
            <el-input type="number" v-model="cardInfo.sortNum" oninput="if(value.length>10)value=value.slice(0,10)"
              placeholder="排序值" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
          </el-form-item>
        </el-form>

      </section>
      <section class="form-footer">
        <el-button type="primary" @click="updateSort()">确定</el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import { addCard, getCardList, editCard, getCardDetail, delCard, sortCard } from "@/api/point/card"
import { getTableList } from "@/api/card/card";
import { ObjToOpt } from "@/utils/utils";
import { exchangeGoodsStatusObj } from "@/db/objs";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      ObjToOpt,
      exchangeGoodsStatusObj,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "", //查询关键字
        goodsStatus: ''
      },
      // 商品分类列表
      typeList: [],
      goodsList: [],
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "", //弹框标题
      // from表单
      rules,
      cardInfo: {
        cardConfigId: '',
        shopGoodsId: "",
        requiredPoints: "",
        requiredAmount: 0,
        goodsStatus: 0,
        sortNum: 1
      },
      // 商品码
      sortShow: false,
      loading: false,
      cardOption: [],//此处用的跟会员权益添加卡券一样的
      cardParams: {
        cardType: "",
        page: 1,
        size: 10,
        kw: "",
      },
      currentPage: 1,
      total: 0,

    };
  },
  created() {
    this.getCardList();
  },
  mounted() {

  },
  methods: {
    reset() {
      this.cardInfo = {
        cardConfigId: '',
        shopGoodsId: "",
        requiredPoints: "",
        requiredAmount: 0,
        goodsStatus: 0,
        sortNum: 1
      }
    },
    // 搜索
    search() {
      this.tableParams.page = 1;
    },
    // 卡券搜索
    remoteMethod(query) {
      this.cardParams.kw = query;
      if (query !== "") {
        this.loading = true;
        this.fetchOptions();
      } else {
        this.cardOption = [];
      }
    },
    // 【请求】卡券下拉选择配置
    fetchOptions() {
      getTableList(this.cardParams)
        .then((res) => {
          if (res.isSuccess == "yes") {
            this.loading = false;
            this.cardOption = res.data.list;
            this.total = res.data.total;

          }
        })

    },
    handlePageChange(page) {
      this.currentPage = page;
      this.cardParams.page = page;
      this.fetchOptions();
    },


    // 【请求】积分兑换卡券列表
    getCardList() {
      getCardList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 【请求】新增积分兑换卡券
    addCard() {

      addCard(this.cardInfo).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.cardInfo.cardName = ''
          this.reload(); // 【请求】表格数据
        }
      });
    },

    // 编辑兑换卡券
    editCard() {

      editCard(this.cardInfo).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false
          this.$message({
            message: res.message,
            type: "success",
          });
         this.reset()
          this.getCardList();
        }
      });
    },
    // 编辑兑换卡券
    sort(row) {
      this.sortShow = true
      this.cardInfo.cardConfigId = row.cardConfigId
      this.cardInfo.sortNum = row.sortNum
    },
    // 更新排序
    updateSort() {
      sortCard(this.cardInfo).then((res) => {
        if (res.isSuccess == "yes") {
          this.sortShow = false
          this.$message({
            message: res.message,
            type: "success",
          });
          this.reload();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getCardList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getCardList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "添加兑换卡券";
      this.fetchOptions();
      console.log(this.cardInfo)
    },
    // 获取卡券详情
    getCardDetail(id) {
      getCardDetail({ cardConfigId: id }).then(res => {
        this.cardInfo = res.data
      })
    },
    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑兑换卡券";
      this.getCardDetail(row.cardConfigId)

    },
    // 【监听】删除
    onDel(row) {
      this.$confirm("是否确定要删除该卡券吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delCard({ cardConfigId: row.cardConfigId }).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.reload(); // 【请求】表格数据
          }
        });
      });
    },
    // 【监听】上架
    onUp(row) {

      this.$confirm("你确定要上架该卡券吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.cardInfo = JSON.parse(JSON.stringify(row));
        this.cardInfo.goodsStatus = 1;
        this.editCard();
      });
    },
    closeDialog() {
      this.reset()
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });

    },
    // 【监听】上架
    onDown(row) {

      this.$confirm("你确定要下架该商品吗?", "商品下架", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.cardInfo = JSON.parse(JSON.stringify(row));
        this.cardInfo.goodsStatus = 0;
        this.editCard();
      });
    },
    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.cardInfo.cardConfigId) {
            this.editCard();
          } else {

            this.addCard();
          }
          this.$refs['form-ref'].resetFields();
        } else {
          return false;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.mall-goods-shop {
  padding: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: center;
}

.export {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.export-success {
  .el-icon-check {
    font-size: 0.48rem;
  }
}
</style>