import request from "@/utils/request";
// 添加积分兑卡券
export function addCard(data) {
  return request("post", "/apm/points/card/create", data);
}
// 修改积分兑卡券
export function editCard(data) {
    return request("post", "/apm/points/card/update", data);
  }
// 获取积分兑卡券列表
export function getCardList(data) {
  return request("post", "/apm/points/card/list", data);
}
// 获取卡券详情
export function getCardDetail(data) {
    return request("post", "/apm/points/card/findById", data);
  }
// 删除积分兑卡券
export function delCard(data) {
  return request("post", "/apm/points/card/del", data);
}
// 排序积分兑卡券
export function sortCard(data) {
  return request("post", "/apm/points/card/sort", data);
}
